.flex-container-sse{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 20px;
    margin-bottom: 10px;
    padding: 15px 0 30px 0;
}

  .Servicio__Mensaje{
    padding: 20px 10px;
  }

  .Servicio__imagen{
    z-index: 1000;
    margin-top: 20%;  
    height: auto;
    width: 400px;
    object-fit: cover;
    margin-right: 20px;
    animation: 2s entrada ;
  }

  .Servicio__Video{
    z-index: 1000;  
    width: 400px;
    height: 300px;
    object-fit: cover;
    margin-right: 20px;
    animation: 2s entrada ;
  }

  @keyframes entrada {
    0% {transform: translateX(-30px); opacity: 0;}
    10% {opacity: 0;}
    70% {opacity: 1;}
    100% {transform: translateX(0px);}
  }

  .contenido  {
    font-size: 12px;
    padding: 0 18px;
    background-color: #eeeeee;

  }

  .Servicio__Bloque{
    width: 50%;
    float: right;
    box-sizing: border-box;
    position: relative;
    animation: 2s entrada2;
  }

  @keyframes entrada2 {
    0% {transform: translateX(100px); opacity: 0;}
    10% {opacity: 0;}
    70% {opacity: 1;}
    100% {transform: translateX(0px);}
  }

  @keyframes entrada3 {
    from {opacity: 0}
    to {opacity: 1;}
  }

  .Servicio__Linea{
    animation: 2s entrada3;
  }

  .preguntas{
    color: #444444; 
    padding: 15px 15px 20px 15px;
    border-radius: 10px;
  }

  .mensaje{
    font-size: 15px;
  }

  @keyframes aparecer {
    0% {opacity: 0;}
    50% {opacity: 0;}
    100% {opacity: 1;}
  }

  @media (max-width: 768px){

    .preguntas{
      width: 100%;
    }

    .Servicio__imagen{
      margin-top: 0;
      width: 100%;
      max-height: 30vh;
      margin-right: auto;
      margin-left: auto;
      margin-bottom: 20px;
    }

    .Servicio__Video{
      margin-top: 0;
      width: 80vw;
      max-width: 450px;
      max-height: 30vh;
      margin-right: auto;
      margin-left: auto;
      margin-bottom: 20px;
    }

    .Servicio__Bloque{
      padding: 0;
      width: 100%;
    }
  }