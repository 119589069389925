.container-servicios{
    margin: 0 0 40px;
}


.Servicios{
    padding: 300px 0 0;
    margin: 10px;
    height: 500px;
    width: 350px;
    text-align: center;
    border: 1px solid gray;
    font-family: sans-serif;
    border-radius: 5%;
    overflow: hidden;
    transition: transform 0.5s, filter 1s;
    
}


.Servicios:hover{
    transform: scale(1.04);
    filter: none;
}

.Servicios:active{
    transform: scale(0.95) rotate(1deg);
}

.ServicioTitulo{
    font-size: 20px;
    color: white;
    position: relative;
    line-height: 1.1;
    padding: 10px;
}

.ServicioTitulo::after{
    content: '';
    position: absolute;
    height: 2px;
    left: 20px;
    bottom: 0;
    width: 305px;
    background: lightgray;
    transform: scaleX(0);
    transition: transform 0.5s;
}

.Servicios:hover .ServicioTitulo::after{
    transform: scaleX(1);
}

.ServicioContenido{
    padding: 30px 0 80px;
    height: auto;
    background: 
    linear-gradient(
        rgba(163,0,0,0),
        rgba(163,0,0,0.7) 10%)
        ;
     transform: translateY(40%);
     transition: transform 0.5s, background-color 0.5s;
     font-family: 'Motiva Sans medium',serif;
}


.Servicios:hover .ServicioContenido{
    transform: translateY(0);
}

.ServicioContenido > *:not(.ServicioTitulo){
    opacity: 0;
    transition: opacity 0.5s;
    transition-delay: 0;    
}

.Servicios:hover .ServicioContenido>*{
    opacity: 1;
    transition: opacity 0.5s;
    transition-delay: 0.5s;   
}

.ServicioTexto {
    font-size: 18px;
    color: white;
}

.ServicioTexto2 {
    margin: 0px;
    font-size: 20px;
    text-align: left;
    padding: 20px;
    height: 1000px;
    color: white;
    width: 50%;
    float: right;
    transform: translateY(-1%);
    background: linear-gradient(rgba(0,0,0,0.4), rgba(0,0,0,0.4));
}


.nada{
    display: none;
}

.flex-container{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 20px;
}

.boton-servicio{
    background: white;
    border-radius: 5px;
    border-style: outset;
    padding: 4px 8px;
}

.boton-servicio:active{
    border-style: inset ;
}

@media (max-width: 768px){
    .Servicios{
        padding: 30px 0 0;
        margin: 5px;
        height: 260px;
        width: 160px;        
    }

    .ServicioContenido{
        height: 400px;
        background: linear-gradient(
         rgba(163,0,0,0),
         rgba(163,0,0,0.7) 10%,
         rgba(163,0,0,0.3) 30%,
         rgba(163,0,0,1));
         transform: translateY(25%);
         transition: transform 0.5s;
        padding: 40px 0 80px;
    }

    .ServicioTitulo{
        font-size: 18px;
    }

    .ServicioTexto{
        font-size: 14px;
    }

    

}

@media (max-width: 320px){
    .Servicios{
        padding: 30px 0 0;
        margin: 5px;
        height: 260px;
        width: 145px;        
    }

    .flex-container{
        justify-content: space-around;
    }

    .container{
        padding-left: 5px;
        padding-right: 5px;
    }

    .ServicioContenido{
        height: 400px;
        background: linear-gradient(
         rgba(163,0,0,0),
         rgba(163,0,0,0.7) 10%,
         rgba(163,0,0,0.3) 30%,
         rgba(163,0,0,1));
         transform: translateY(25%);
         transition: transform 0.5s;
        padding: 40px 0 80px;
    }

    .ServicioTitulo{
        font-size: 16px;
    }

    .ServicioTexto{
        font-size: 14px;
    }

}