.FContainer{
    position: fixed;
    bottom: 30px;
    right: 30px;
    height: 110px;
    width: 60px;
    z-index: 100;
}

@media screen and (max-width: 767px) {
    .FContainer{
        bottom: 15px;
        right: 15px;
    }
}