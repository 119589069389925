.Contenedor-Servicio{
    text-align: center;
    padding-top: 10px;
    background-color: white;
    border: 0;
    cursor: pointer;
    max-width: 300px;
}

@media (max-width: 768px){
    .Contenedor-Servicio{
        max-width: 160px;
    }
}

.Contenedor__Titulo {
    font-size: 20px;
    color: #444444;
    font-weight: bold;
    margin: 0.5px;
    font-family: 'Motiva Sans bold',serif;

}

.Contenedor__Mensaje{
    font-size: 17px;
    color: #444444;
    
    font-family: 'Motiva Sans medium',serif;
}


.Contenedor-Servicio:hover .Contenedor__Titulo{
    color: #c45656;
}

.Contenedor-Servicio:hover .Contenedor__Mensaje{
    color: #c45656;
}