.logo{
    margin-top: 30px;
    height: 8em;
}


.Nosotros{
    text-align: center;
    color: #444444;
}

.Nosotros_imagen{
    float: left;
    max-width: 500px;
    margin-bottom: 30px;
}


.Nosotros__Titulo{
    color: #444444;
}

.Nosotros__Mensaje{
    color: #444444;
    padding: 0 200px;
    font-size: 20px;
}

.Nosotros__Valores{
    color: #444444;
    margin-top: 10px;
    margin-bottom: 20px;
}


.Nosotros__Mensaje2{
    text-align: left;
    margin-left: 10px;
    margin-right: 10px;
    margin-bottom: 50px;
    font-size: 20px;
    text-Indent: 40px;
    color: #444444;
}

.Nosotros__Titulo2{
    display: inline-block;
    margin-top: 30px;
    margin-left: 25px;
    padding: 0 20px;
    border-radius: 20px 0;
    background-color: #a30000;
    font-size: 30px;
    text-align: right;
    color: white;
    border-style: outset;
}

.Nosotros__ValoresTituloIzq{
    display: block;
    padding: 0 10px;
    border-radius: 0 20px 20px 0;
    background-color: #a30000;
    font-size: 30px;
    color: white;
    border-style: outset;
}

.Nosotros__ValoresTituloDer{
    display: block;
    padding: 0 10px;
    border-radius: 20px 0 0 20px;
    background-color: #a30000;
    font-size: 30px;
    color: white;
    border-style: outset;
}

.Nosotros__ValoresIzq{
    color: #444444;
    margin-top: 10px;
    margin-bottom: 20px;
    text-align: left;
    padding: 0 0 0 20px;
}

.Nosotros__ValoresDer{
    color: #444444;
    margin-top: 10px;
    margin-bottom: 20px;
    text-align: right;
    padding: 0 20px 0 0;
     
}

@media (max-width: 768px){
    .Nosotros_imagen{
        max-width: 80vw;
    }

    .Nosotros__Titulo{
        font-size: 35px;
        margin-bottom: 0px;
    }

    .Nosotros__Titulo2{
        font-size: 28px;
        padding: 0 15px;
    }

    .Nosotros__Mensaje{
        padding: 10px 20px;
        font-size: 18px;
    }

    .Nosotros__Mensaje2{
        font-size: 18px;
    }

    .Nosotros__ValoresDer {
        font-size: 16px;
    }

    .Nosotros__ValoresTituloDer{
        font-size: 18px;
        padding: 0 5px;
    }

    .Nosotros__ValoresTituloIzq{
        font-size: 18px;
        padding: 0 5px;
    }

    .Nosotros__ValoresIzq{
        font-size: 16px;
    }
}
/*
.Nosotros__Fondo{
    padding: 15px;
    background-color: #a30000;
}*/