.botonColapsable .toggle{
    width: 100%;
    padding: 0 20px;
    font-size: 18px;
    background: #ffffff;
    border: 2px  #a30000;
    border-style: ridge;
    cursor: pointer;
    border-radius: 50px;
    display: flex;
    margin: 10px 0 5px 0;
    align-items: center;
}

.botonColapsable .contenido{
    padding: 0 15px;
    background: white;
    border-left: 1px solid #a30000;
    margin-left: 15px;
    font-size: 14px;
    text-align: justify;
    overflow: hidden;
    transition: all .5s ease-in;
    
}

.botonColapsable .contenidoPadre{
    height: 0px;
    overflow: hidden;
    transition: height ease 0.3s;
}

.toggle.activo svg{
    transform: rotate(180deg);
}

.Boton__Icono{
    transition: transform 0.5s ease;
}
/* 
.botonColapsable .toggle{
    background-color: #a30000;
    color: white;
    border: unset;
    font-weight: bold;
    padding: 0.3rem;
    width: 100%;
    border-radius: 5px;
    margin-top: 1rem;
}

.botonColapsable .contenido{
    border: 1px solid black;
    padding: 0.5 rem;
    border-radius: 5px;
    margin-bottom: 0.5rem;
    
}


.toggle:hover{
    background: #e30000;
}

*/