.carousel .thumb img {
    width: 100% !important;
    height: 100% !important;
}

.carousel .slide img {  
    /* change this to whatever you want */
    max-width: auto;
    height: 576px;
}

.carousel.carousel-slider .control-arrow {
    width: 50px
}