.bloque-flex-container {
    text-align: center;
    padding: 40px 0;
    background-size: 30px 30px, 30px 30px;
    position: relative;
    width: 100%;
    max-width: 2160px;
    margin-right: auto;
    margin-left: auto;
    margin-bottom: 5%;
}

.BS-texto {
    text-align: left;
    margin: 0;
    padding: 0.5em 1.5em;
    width: 50vw;
    max-width: 600px;
}

.bloque__servicio {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    margin-bottom: 3em;
}

.BS-ServicioPrincipal {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
}

.BS-TituloContenedor {
    color: #a30000;
    font-size: 40px;
    margin: 15px 0 70px;
    display: inline-block;
    font-weight: bold;
    font-family: 'Motiva Sans Bold', serif;
}

@media (max-width: 768px) {
    .BS-texto {
        display: none;
        width: 0;
    }
    .BS-TituloContenedor {
        font-size: 25px;
    }
}