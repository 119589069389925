
.activador{
    height: 600px;
    width: auto;
    max-width: 540px;
    border-radius: 40px;
    
}


.contenedor{
    padding-top: 50px;
    text-align: center;
    height: 600px;
    width: auto;
    padding: 0 20px;
    max-width: 540px;   
    align-items: center;
    transition-delay: 0.2s, 0s;
    background-size: cover;
    border-radius: 40px;
    
    
    
}

/*
.titulo{
        display: inline-block;
        padding-right: 10px;
        margin-top: 200px;
        margin-bottom: 20px;
        font-size: 50px;
        font-weight: bold;
        font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
        padding-right: 20px;
        padding-left: 20px;
        transition: margin-top 0.5s;
        color: #a30000;
        text-shadow: 2px 0 0 #fff, -2px 0 0 #fff, 0 2px 0 #fff, 0 -2px 0 #fff, 1px 1px #fff, -1px -1px 0 #fff, 1px -1px 0 #fff, -1px 1px 0 #000;
    }
*/

.titulo{
    display: inline-block;
    padding-right: 10px;
    margin-top: 150px;
    margin-bottom: 20px;
    font-size: min(max(30px, 8vw), 50px);
    font-weight: bold;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    padding-right: 5%;
    padding-left: 5%;
    transition: margin-top 0.5s;
    color: #a30000;
    text-shadow: 2px 0 0 #fff, -2px 0 0 #fff, 0 2px 0 #fff, 0 -2px 0 #fff, 1px 1px #fff, -1px -1px 0 #fff, 1px -1px 0 #fff, -1px 1px 0 #000;
}



.informacion{
    font-family: 'Motiva Sans bold',serif;

    margin-top: 0;
    color: white;
    margin-bottom: 0;
    font-size: min(max(8px, 5vw), 20px);
    padding: 20px 40px;
    text-shadow: 2px 0 0 #000, -2px 0 0 #000, 0 2px 0 #000, 0 -2px 0 #000, 1px 1px #000, -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000;
}

.imagen{
    border: black 5px solid;
    max-height: 250px;
    transition: max-height 0.5s;
}

.boton{
    margin-top: 20px;
    width: 35%;
    font-weight: bold;
    display: none;
    visibility: hidden;
    border-radius: 10%;
    background-color: #a30000;
    color: white;
    padding: 5px 10px;
    cursor: pointer;
    font-family: 'Motiva Sans Bold',serif;
    transition: transform 0.4s;
}

.boton:hover{
    transform: scale(1.1);
}

.informacion2{
    display: none;
    visibility: hidden;
    margin-top: 0;
    color: white;
    font-size: min(max(18px, 2vw), 20px);
    font-family: 'Motiva Sans Bold',serif;
    padding: 20px;
    text-shadow: 2px 0 0 #000, -2px 0 0 #000, 0 2px 0 #000, 0 -2px 0 #000, 1px 1px #000, -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000;
}

.activador:hover .contenedor{
    animation: fondocontenedor 0.3s;
    max-height: initial;
    animation: oscurecer 0.4s;
    background: linear-gradient(rgba(0,0,0,0.6), rgba(0,0,0,0.6));

}

.activador:hover .titulo{
    margin-top: 50px;
}

.activador:hover .informacion{
    display: none;
}

.activador:hover .imagen{
    max-height: 0%;
    visibility: hidden;
}

.activador:hover .boton{
    animation: aparecer 0.5s;
    display: inline;
    visibility: visible;
}

.activador:hover .informacion2{
    animation: aparecer 0.5s;
    display: inline;
    visibility: visible;
}

@keyframes aparecer {
    0% { visibility: hidden; }
    50% { visibility: hidden; }
    100% { visibility: visible; }
}

@keyframes oscurecer {
    0% { background: linear-gradient(rgba(0,0,0,0.0), rgba(0,0,0,0.0));}
    6.6% { background: linear-gradient(rgba(0,0,0,0.04), rgba(0,0,0,0.04));}
    13.2% { background: linear-gradient(rgba(0,0,0,0.08), rgba(0,0,0,0.08));}
    19.8% { background: linear-gradient(rgba(0,0,0,0.12), rgba(0,0,0,0.12));}
    27.4% { background: linear-gradient(rgba(0,0,0,0.16), rgba(0,0,0,0.16));}
    34% { background: linear-gradient(rgba(0,0,0,0.2), rgba(0,0,0,0.2));}
    40.6% { background: linear-gradient(rgba(0,0,0,0.24), rgba(0,0,0,0.24));}
    47.2% { background: linear-gradient(rgba(0,0,0,0.28), rgba(0,0,0,0.28));}
    53.8% { background: linear-gradient(rgba(0,0,0,0.32), rgba(0,0,0,0.32));}
    60.4% { background: linear-gradient(rgba(0,0,0,0.36), rgba(0,0,0,0.36));}
    67% { background: linear-gradient(rgba(0,0,0,0.4), rgba(0,0,0,0.4));}
    73.6% { background: linear-gradient(rgba(0,0,0,0.44), rgba(0,0,0,0.44));}
    80.2% { background: linear-gradient(rgba(0,0,0,0.48), rgba(0,0,0,0.48));}
    86.8% { background: linear-gradient(rgba(0,0,0,0.52), rgba(0,0,0,0.52));}
    93.4% { background: linear-gradient(rgba(0,0,0,0.56), rgba(0,0,0,0.56));}
    100% { background: linear-gradient(rgba(0,0,0,0.6), rgba(0,0,0,0.6));}


}

@media (max-width: 768px){
    .titulo{
        display: inline-block;
        margin-top: 150px;
        font-size: min(max(30px, 8vw), 50px);
        padding: 5px;

        }

        .activador:hover .informacion2{
            font-size: min(max(8px, 5vw), 20px);

        }

        .boton {
            width: 50%;
            font-size: min(max(7px, 4vw), 15px);

        }

        .informacion{
            font-size: min(max(8px, 6vw), 22px);
            padding: 5px 0;
        }

        .activador:hover .titulo{
            margin-top: 25px;
        }

        .activador{
            margin-left: 5%;
            width: 90%;
        }
}

