.OtrosTituloContenedor{
    color: #a30000;
    font-size: 40px;
    margin: 15px 0 70px;
    background-color: white;
    text-align: center;
    display: inline-block;
    text-align: center;
    font-weight: bold;
    font-family: 'Motiva Sans Bold',serif;
}

.OtrosTituloContenedor>img{
    margin: 0 20px
}


.Otros-flex-container{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: flex-start;
    margin: 0 100px;
    max-width: 1200px;        
    margin-right: auto;
    margin-left: auto;
}

.OtrosContainer{
    margin: 50px 0;
    text-align: center;
}

@media (max-width: 768px ){
    .Otros-flex-container{
        margin: 0;
    }

    .OtrosTituloContenedor{
        font-size: 30px;
    }
}