.Clientes__Titulo{
    background-Color: #a30000;
    font-size: 40px;
    font-family: 'Motiva Sans Bold',serif;
    padding: 20px;
    text-align: center;
    color: white;
}

.Clientes__Logo__Imagen{
    height: 150px;
    margin: 10px 20px;
    padding: 15px;
    width: 150px;
    border-radius: 50%;
    object-fit: contain;
    background-color: white;
    transition: transform 0.5s;
}

.Clientes__Logo__Imagen:hover{
    transform: scale(1.1);

}

.Clientes__Logo__Container{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}



@media (max-width: 768px){

    .Clientes__Titulo{
        font-size: 30px;
    }

    .Clientes__Logo__Imagen{
        height: 100px;
        width: 100px;
        margin: 5px;
        padding: 8px;
    }

}